var repree = repree || {};
repree.Language = repree.Language || {};

/**
 * repree.Language contains all the functions required to update the app language
 */
repree.Language = {

    /**
     * Drops a cookie to preserve the user's language selection
     */
    SetLanguageCookie : function (languageCode) {
        $.cookie('rep_language', languageCode, { expires: new Date(2147483647000) });
    },

    SaveLanguage : function(language, type, data){
        var postData = {};

        switch (type.toLowerCase()) {
            case "signer":
                postData.signerId = data.id;
                postData.language = language;
                $.post('/Sign/SaveSignerLanguage', postData);
                break;
        }
    },

    /**
     * Retrieves the browser language or the language code stored in the language cookie
     * @returns {string} The language to use by default
     */
    GetBrowserLanguage : function () {
        //Note: cookies are not supported when running on localhost, use 127.0.0.1 instead
        var cookieLanguage = $.cookie('rep_language');
        if (cookieLanguage) return cookieLanguage;

        return navigator.languages
            ? navigator.languages[0]
            : (navigator.language || navigator.userLanguage);
    },

    /**
     * Assigns the i18n locale value to a specified language code.
     * The Language code is truncated to the first two letters
     * If the locale is not defined in i18n, it will default to English
     * @param {string} languageCode - The language to use in-app. If no code is specified, the browser language is used as a default
     * @param {bool} setCookie - Flag for whether the user's language cookie should be updated
     */
    SetLocale : function (languageCode, setCookie) {
        languageCode = languageCode || this.GetBrowserLanguage();
        languageCode = languageCode.slice(0, 2);

        $.i18n().locale = languageCode;

        $.ajaxSetup({
            beforeSend: function (jqXHR, settings) {
                jqXHR.setRequestHeader('accept-language', languageCode);
            }
        })

        if (setCookie) this.SetLanguageCookie(languageCode);
    },

    /**
     * Updates the language of any elements with the specified selector.
     * Note that these elements must have the data-i18n attribute defined
     * @param {string} selector - selector for the elements to update. Defaults to elements with a class of i18n if left blank
     */
    UpdateText : function (selector) {
        selector = selector || '.i18n';

        var $items = $(selector);

        var updateItems = $items.filter('[type!=checkbox]');
        updateItems.i18n();

        //Translate Pretty Checkable Labels
        //Note: Assumes that the input element has a class of 'i18n' and a 'data-i18n' attribute
        var checkBoxItems = $items.filter('[type=checkbox]');
        var that = this;
        _.each(checkBoxItems, function (item) {
            if (item.type === 'checkbox' && item.getAttribute('data-label')) {
                that.UpdatePrettyCheckableLabels(item);
            }
            else {
                $(item).i18n();
            }
        });
    },

    /**
     * Updates the language of any elements with the specified selector by explicitly setting the element's inner HTML.
     * Note that these elements must have the data-i18n attribute defined
     * @param {string} selector - selector for the elements to update. Defaults to elements with a class of i18n-html if left blank
     */
    UpdateHtml : function (selector) {
        selector = selector || '.i18n-html';

        var $items = $(selector);

        _.each($items, function (item) {
            var htmlString = $.i18n(item.getAttribute('data-i18n'));
            if (!htmlString) return;

            item.innerHTML = htmlString;
        });
    },

    /**
     * Updates the language of both text and html elements
     */
    Update : function (textSelector, htmlSelector) {
        this.UpdateText(textSelector);
        this.UpdateHtml(htmlSelector);
    },

    /**
     * Retrieves the translated message and assigns it to the PrettyCheckable label
     * @param {object} checkbox - The underlying input element of type checkbox
     */
    UpdatePrettyCheckableLabels : function(item) {
        if (item.parentNode.className.indexOf('prettycheckbox') < 0) return

        var msg = $.i18n(item.getAttribute('data-i18n'));

        if (!msg) return;

        item.nextElementSibling.nextElementSibling.textContent = msg;
    }
};

//To add additional languages, add the language code to the list below
//Ensure that there is a corresponding JS file in /Scripts/languages/
var loadObj = {};
loadObj["en"] = repree.LanguageData.en;
loadObj["fr"] = repree.LanguageData.fr;
$.i18n().load(loadObj).then(function () {
    repree.Language.SetLocale();
    repree.Language.Update();
});